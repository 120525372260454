import React from 'react';
import Layout from '../components/layout';
import * as styles from './termini-condizioni.module.css';

export default function TerminiCondizioni() {
    return (
        <Layout minimal={true}>
            <div className={styles.container}>
                <div className={styles.center}>
                    <h2>Termini e condizioni</h2>
                    <p>
                        Le presenti condizioni generali (“Condizioni Generali” o “Contratto”) disciplinano i termini e
                        le condizioni d’uso ai quali BAUMANN s.r.l., proprietario dei diritti di uso del marchio BAUMANN
                        e del relativo portale e dell’Applicazione, con sede legale Centro Direzionale Isola E3, Piano
                        9, 80143, Napoli, Codice Fiscale e Partita IVA IT09904041218 (il “Fornitore” o “BAUMANN”)
                        fornirà alla persona, fisica o giuridica, ovvero l’ente, pubblico o privato, ovvero
                        l’associazione, individuata/o come cliente (il “Cliente”) il propri servizio.
                    </p>
                    <p>
                        BAUMANN fornisce un servizio di abbonamento che consente ai propri abbonati di usufruire dei
                        servizi di amministrativi, contabili e fiscali (di seguito definiti come “Servizi
                        Professionali") attraverso accesso autenticato all’infrastruttura ("di seguito definita come
                        “Applicazione" e, insieme ai Servizi Professionali, “MyBaumann”).
                    </p>
                    <h4>1. Creazione Account e perfezionamento del Contratto</h4>
                    <ol>
                        <p>
                            1.1. Il Cliente può scegliere di registrarsi (i) tramite accesso al sito mybaumann.it, (ii)
                            tramite accesso all’Applicazione dal proprio tablet o smartphone.
                        </p>
                        <p>
                            1.2. Nei casi (i) e (ii) il Cliente dovrà inserire le proprie credenziali, costituite da un
                            username e da una password (Chiavi di Accesso) ovvero una mail e una password entrambe
                            fornite dal Cliente in fase di registrazione.
                        </p>
                        <p>
                            1.3. Il Cliente riceverà una mail di conferma all’indirizzo email tramite il quale si è
                            registrato con un link per convalidare la mail fornita.
                        </p>
                        <p>
                            1.4. La creazione dell’account, previa integrale accettazione delle presenti Condizioni
                            Generali da parte del Cliente, costituisce momento perfezionativo del Contratto, richiesto
                            per l’attivazione del Servizio BAUMANN.
                        </p>
                    </ol>
                    <h4>2. Abbonamento al Servizio BAUMANN</h4>
                    <ol>
                        <p>2.1. Durata. L'abbonamento a Servizio BAUMANN continuerà fino alla disdetta.</p>
                        <p>2.2. Maggiore età. Per abbonarsi al Servizio BAUMANN è necessario avere almeno 18 anni.</p>
                        <p>
                            2.3. Abbonamento. Per poter utilizzare il Servizio BAUMANN, il Cliente deve disporre di
                            accesso a Internet e di un dispositivo compatibile con l’Applicazione; deve inoltre fornire
                            uno o più Metodi di pagamento. Per "Metodo di pagamento" si intende un metodo di pagamento
                            attuale, valido e accettato. A meno che il Cliente non provveda a disdire il suo abbonamento
                            prima della relativa data di fatturazione, BAUMANN è autorizzato ad addebitare la quota di
                            abbonamento del ciclo di fatturazione successivo sul Metodo di pagamento prescelto.
                        </p>
                        <p>
                            2.4. Piani di Abbonamento. BAUMANN offre diversi piani di abbonamento. Alcuni piani di
                            abbonamento potrebbero comportare diverse condizioni o limitazioni che verranno rese note
                            alla registrazione o con altri metodi di comunicazione.
                        </p>
                    </ol>
                    <h4>3. Servizio BAUMANN</h4>
                    <ol>
                        <p>
                            3.1. Natura dell’incarico. BAUMANN fornirà al Cliente, attraverso l’Applicazione, le
                            seguenti prestazioni:
                        </p>
                        <ol>
                            <p>
                                (i) con riferimento al regime fiscale forfettario: la tenuta della contabilità
                                forfettaria, la elaborazione della dichiarazione dei redditi, le comunicazioni agli enti
                                e la predisposizione degli F24 per il pagamento dei tributi;
                            </p>
                            <p>
                                (ii) con riferimento al regime fiscale semplificato: la tenuta della contabilità
                                semplificata, la elaborazione delle dichiarazioni ivi compresa la dichiarazione dei
                                redditi e la dichiarazione IVA, le comunicazioni agli Enti e la predisposizione degli
                                F24 per il pagamento dei tributi.
                            </p>
                        </ol>
                        <p>
                            3.2. Lista delle prestazioni. A titolo esemplificativo e non esaustivo, rientrano nei
                            Servizi Professionali, le seguenti prestazioni:
                        </p>
                        <ol>
                            <p>
                                (i) assistenza per l’elaborazione dei dati contabili e delle scritture obbligatorie ai
                                fini delle imposte dirette e indirette;
                            </p>
                            <p>
                                (ii) assistenza per l’elaborazione del libro giornale, dei conti di mastro e dei
                                registri I.V.A. e perfezionamento della stampa nei termini di legge;
                            </p>
                            <p>(iii) assistenza per l’elaborazione delle liquidazioni periodiche I.V.A.;</p>
                            <p>(iv) consulenza per la predisposizione dei modelli Intrastat;</p>
                            <p>(v) consulenza per la predisposizione dei modelli Esterometro;</p>
                            <p>
                                (vi) consulenza per la predisposizione del modello Redditi Persone Fisiche, delle
                                Certificazioni Uniche e del modello 770; assistenza per l’inoltro telematico delle
                                stesse e delle comunicazioni periodiche IVA;
                            </p>
                            <p>
                                (vii) assistenza nei rapporti con l’Amministrazione Finanziaria in relazione alla
                                trattazione avvisi bonari pervenuti a seguito di controlli automatizzati.
                            </p>
                        </ol>
                        <p>
                            Sono espressamente escluse dalle previsioni della presente proposta le prestazioni relative
                            al contenzioso tributario, in ogni suo grado di giudizio.
                        </p>
                        <p>
                            3.3. Lista delle prestazioni. Il Cliente prende atto che è suo impegno fornire a BAUMANN
                            tutta la documentazione necessaria per il compimento di vari adempimenti entro le scadenze
                            richieste dal Fornitore. Nel caso in cui il Cliente non dovesse mandare tutta la
                            documentazione necessaria, nei tempi richiesti, è possibile che il Fornitore non possa
                            compiere uno o più adempimenti e, pertanto, alcuna responsabilità potrà essere addebitata
                            alla BAUMANN.
                        </p>
                    </ol>
                    <h4>4. Fatturazione</h4>
                    <ol>
                        <p>
                            4.1. Corrispettivi. Il Cliente è tenuto al pagamento dei corrispettivi per la fruizione del
                            Servizio BAUMANN indicati nel sito http://www.mybaumann.it e accettati durante la
                            registrazione secondo il Ciclo di fatturazione sotto descritto.
                        </p>
                        <p>
                            4.2. Ciclo di fatturazione. La quota dell'abbonamento del Servizio BAUMANN sarà addebitata
                            sul Metodo di pagamento periodicamente. La durata del ciclo di fatturazione dipenderà dal
                            tipo di abbonamento scelto al momento della registrazione al servizio. In alcuni casi la
                            data del pagamento può variare, ad esempio nel caso in cui l'addebito sul tuo Metodo di
                            pagamento non andasse a buon fine, qualora venisse modificato il piano di abbonamento o la
                            data di prossimo pagamento cadesse in un giorno non presente in un determinato mese.
                        </p>
                        <p>
                            4.3. Metodi di pagamento. Per utilizzare il Servizio BAUMANN il Cliente deve indicare uno o
                            più Metodi di pagamento. Il Cliente autorizza BAUMANN ad addebitare qualsiasi Metodo di
                            pagamento associato all’account del Cliente qualora il Metodo di pagamento principale venga
                            rifiutato o non possa essere utilizzato per saldare la quota di abbonamento.
                        </p>
                        <p>
                            4.4. Insoluti. Il Cliente sarà responsabile di eventuali importi insoluti. Qualora un
                            addebito non andasse a buon fine a causa della scadenza del Metodo di pagamento, fondi
                            insufficienti o per qualsiasi altro motivo, e qualora il Cliente non avesse disdetto il suo
                            abbonamento, potremmo sospendere l’accesso al servizio fino a quando non avremo addebitato
                            con successo un Metodo di pagamento valido.
                        </p>
                        <p>
                            4.5. Aggiornamento dei Metodi di pagamento. Il cliente può aggiornare i Metodi di pagamento
                            nella pagina "Metodi di pagamento". BAUMANN potrebbe aggiornare i Metodi di pagamento del
                            Cliente in base ai dati ricevuti dai gestori dei servizi di pagamento. A seguito di ogni
                            aggiornamento, ci autorizzi a continuare ad addebitare i Metodi di pagamento disponibili.
                        </p>
                        <p>
                            4.6. Modifiche al prezzo e ai piani di abbonamento. Di tanto in tanto potremmo modificare i
                            nostri piani di abbonamento e il prezzo del nostro servizio; tuttavia, qualsiasi modifica
                            del prezzo o dei piani di abbonamento avrà decorrenza non prima di 30 giorni dalla data in
                            cui ti invieremo la relativa comunicazione.
                        </p>
                    </ol>
                    <h4>5. Recesso e Risoluzione</h4>
                    <ol>
                        <p>
                            5.1. Disdetta. Il cliente può disdire il suo abbonamento al Servizio BAUMANN in qualsiasi
                            momento, continuando ad avere accesso al Servizio fino alla fine del periodo di
                            fatturazione: (i) tramite l’accesso alla sezione “Servizi e Abbonamenti” dell’Applicazione
                            (ii) mandando una PEC all’indirizzo baumann-srl@legalmail.it.
                        </p>
                        <p>
                            5.2. Rimborsi. La disdetta del servizio non da diritto a nessun rimborso nei confronti del
                            Cliente.
                        </p>
                        <p>
                            5.3. Recesso dal Contratto. BAUMANN si riserva la facoltà di recedere dal Contratto in
                            qualsiasi momento e senza obbligo di motivazione, dandone comunicazione al Cliente anche a
                            mezzo mail, con un preavviso di almeno 30 (trenta) giorni, fatto salvo il caso di eventi
                            determinati da cause di forza maggiore, in virtù dei quali il BAUMANN si riserva il diritto
                            di recedere dal presente contratto con effetto immediato. In ogni caso, resta espressamente
                            esclusa ogni responsabilità di BAUMANN per l’esercizio del diritto di recesso e/o per il
                            mancato utilizzo del Servizio da parte del Cliente ovvero il conseguente diritto di questi a
                            pretendere ogni altro rimborso o indennizzo o risarcimento di qualsiasi tipo e genere.
                        </p>
                        <p>
                            5.4. Adempimenti Fiscali. Con la disdetta o risoluzione del Contratto cessa anche qualsiasi
                            obbligo di BAUMANN alla lavorazione di qualsivoglia adempimento fiscale e o civilistico
                            successivamente in scadenza anche se riguardano fatti fiscali maturati sotto la vigenza del
                            contratto.
                        </p>
                        <p>
                            5.5. Restituzione dei Dati. Il Cliente potrà eseguire il backup dei propri dati elaborati
                            mediante l’utilizzo dell’Applicazione in qualsiasi momento per tutta la durata del presente
                            Contratto. Decorso tale termine i dati saranno ulteriormente recuperabili entro 90 giorni.
                            Il Cliente prende atto che dopo 90 giorni dalla cessazione del Contratto non sarà più
                            possibile recuperare eventuali dati e/o informazioni e/o contenuti da egli immessi e/o
                            trattati nell’infrastruttura virtuale e si impegna, ora per allora, a procurarsi
                            tempestivamente prima della definitiva cessazione del Contratto una copia di tali dati e/o
                            informazioni e/o contenuti con le modalità previste dall’Applicazione (es. esportazione
                            PDF). In ogni caso per qualsiasi caso di cessazione del Contratto il Cliente solleva, ora
                            per allora, BAUMANN da ogni e qualsiasi responsabilità per l’eventuale perdita o il
                            danneggiamento totale o parziale di dati e/o informazioni e/o contenuti immessi e/o trattati
                            dal cliente stesso. In ogni caso la documentazione fiscale in possesso di BAUMANN potrà
                            essere sempre e comunque richiesta in qualsiasi momento, dato che trattasi di documentazione
                            elaborata da BAUMANN, ma di proprietà del Cliente.
                        </p>
                        <p>
                            5.6. Risoluzione. L’inadempimento da parte del Cliente delle obbligazioni di cui agli
                            articoli 6, 7, 8 e 9 del presente Contratto, ove non determinato da caso fortuito o forza
                            maggiore, comporterà la risoluzione di diritto del contratto ex art. 1456 c.c.. È fatto
                            comunque salvo il diritto del Fornitore di ottenere il risarcimento di tutti i danni subiti.
                        </p>
                    </ol>
                    <h4>6. Uso dell’Applicazione</h4>
                    <ol>
                        <p>
                            6.1. Gestione dell’Account. Il Cliente che ha creato l'account BAUMANN e il cui Metodo di
                            pagamento viene addebitato è responsabile per ogni attività che si svolge tramite l'account
                            BAUMANN. Per mantenere il controllo dell'account e impedire a chiunque di accedere
                            all'account (e quindi anche a informazioni sulla cronologia delle visualizzazioni
                            dell'account), il Cliente dovrà mantenere il controllo dei dispositivi compatibili con
                            BAUMANN, utilizzati per accedere al servizio, e non dovrà comunicare a nessuno la password o
                            i dettagli del Metodo di pagamento associato all'account. Sarà tua responsabilità aggiornare
                            le informazioni che ci fornisci in merito al tuo account e assicurarti che siano accurate.
                            Avremo la facoltà di chiudere o sospendere il tuo account al fine di proteggere il Cliente,
                            BAUMANN o i nostri partner da furti di identità o altre attività fraudolente.
                        </p>
                        <p>
                            6.2. Dispositivi Compatibili. Il Cliente prende atto che per utilizzare il Servizio BAUMANN
                            dovrà dotarsi delle apparecchiature elettriche, elettroniche o di qualsivoglia altro genere,
                            dei software, dei servizi telefonici e/o di rete e di quant’altro necessario e che,
                            pertanto, BAUMANN non comprende l’erogazione di tali strumenti necessari per l’accesso alla
                            rete internet. Il Cliente si impegna inoltre a mantenere il BAUMANN indenne da ogni pretesa
                            che possa essere avanzata nei confronti di quest’ultimo a qualsiasi titolo o in qualsiasi
                            modo collegate alla inidoneità dei sistemi hardware e/o di rete e/o di software atti a
                            consentire la corretta fruizione del servizio. Il Cliente riconosce che la rete internet non
                            è controllata da BAUMANN e che, per la peculiare struttura della suddetta rete, nessuna
                            entità pubblica o privata e neppure BAUMANN è in grado di garantire e monitorare le
                            prestazioni e la funzionalità dei rami della rete e di controllare i contenuti delle
                            informazioni che sono trasmesse mediante la propria rete. Per questo motivo nessuna
                            responsabilità potrà essere imputata al BAUMANN per la trasmissione o la ricezione di
                            informazioni illegali di qualsiasi natura e specie.
                        </p>
                        <p>
                            6.3 Malfunzionamenti o Guasti. In caso di segnalazione di guasti o malfunzionamenti, il
                            Cliente si impegna a fornire tutte le specifiche e le informazioni eventualmente richieste
                            dal Fornitore.
                        </p>
                        <p>
                            6.4. Uso improprio dell’Applicazione. Il Cliente accetta di non conservare, riprodurre,
                            distribuire, modificare, mostrare, pubblicare, concedere in licenza, creare opere derivate
                            da, offrire in vendita o utilizzare (fatto salvo ove espressamente autorizzato nelle
                            presenti Condizioni di utilizzo) contenuti e informazioni sul Servizio BAUMANN o
                            dell’Applicazione. Accetta inoltre di non: decompilare, sottoporre a reverse engineering o
                            disassemblare l’Applicazione; inserire qualsiasi codice o prodotto o manipolare il contenuto
                            del Servizio BAUMANN in qualsiasi modo; Accetta inoltre di non caricare, postare, inviare
                            tramite posta elettronica o altrimenti inviare o trasmettere qualsiasi materiale progettato
                            per interrompere, distruggere o limitare le funzionalità di qualsiasi software per computer
                            o hardware o terminale di telecomunicazione associato al Servizio BAUMANN, compresi virus e
                            qualsiasi altro codice, file o programma per computer. BAUMANN avrà la facoltà di limitare o
                            interrompere l'utilizzo del servizio qualora ritenesse il Cliente violare le presenti
                            Condizioni di utilizzo o utilizzasse il servizio in maniera illecita o fraudolenta.
                        </p>
                        <p>
                            6.5. Manutenzione. BAUMANN, farà ogni ragionevole sforzo per garantire la massima
                            disponibilità del servizio. Il Cliente prende atto ed accetta che BAUMANN potrà sospendere
                            e/o interrompere il servizio per garantire gli interventi di manutenzione ordinaria o
                            straordinaria che si rendano opportuni e/o necessari sia ai locali che ospitano
                            l’infrastruttura che ai server e/o apparecchiature ivi contenute. In tali casi, BAUMANN si
                            impegna a ripristinare, i servizi, nel minor tempo possibile al fine di ridurre il disagio
                            creato al Cliente.
                        </p>
                    </ol>
                    <h4>7. Identificazione del Cliente</h4>
                    <ol>
                        <p>
                            7.1. Dati Personali. Il Cliente ha l’obbligo di comunicare a BAUMANN tempestivamente i
                            propri dati personali e garantisce che gli stessi siano corretti, aggiornati e veritieri. Il
                            Cliente si impegna inoltre a comunicare tempestivamente ogni variazione dei dati personali.
                            Il BAUMANN può prevedere sistemi di identificazione idonei allo scopo di cui alla normativa
                            antiriciclaggio.
                        </p>
                        <p>
                            7.2. Fornitura di dati falsi. Il Cliente prende atto ed accetta che, qualora abbia
                            comunicato al BAUMANN dati falsi, non attuali o incompleti, quest’ultimo si riserva il
                            diritto di sospendere l’accesso all’Applicazione nonché la sospensione dei Servizi
                            Professionali e/o di risolvere il Contratto ai sensi dell’articolo 1456 del codice civile,
                            trattenendo le somme pagate dal Cliente e riservandosi il diritto di chiedere il
                            risarcimento del maggior danno. Resta in ogni caso inteso che tutti i dati comunicati dal
                            Cliente a BAUMANN saranno coperti dall’obbligo di riservatezza di cui al presente Contratto.
                            In caso di fornitura di dati falsi il BAUMANN provvederà senza indugio a segnalare il fatto
                            alle autorità competenti.
                        </p>
                    </ol>
                    <h4>8. Garanzie e Responsabilità</h4>
                    <ol>
                        <p>
                            8.1. Interruzioni di servizio. Il Cliente prende atto e accetta che BAUMANN non rilascia
                            dichiarazioni e garanzie espresse o implicite sul fatto che il servizio sia adatto a
                            soddisfare le esigenze del Cliente o che sia esente da errori. Il Cliente prende atto che
                            BAUMANN, in nessun caso, potrà essere ritenuto responsabile per qualsiasi danno dovesse
                            derivare al Cliente o a terzi in conseguenza di ritardi, mancato svolgimento o
                            malfunzionamenti e/o interruzioni nell’erogazione del servizio.
                        </p>
                        <p>
                            8.2. Contenuti. Il Cliente riconosce di essere l’unico responsabile per i contenuti immessi,
                            presenti, transitati e/o conservati sui server che ospitano il Servizio BAUMANN, salvo che
                            non siano documenti immessi da BAUMANN e riguardanti l’erogazione dei servizi, e si obbliga
                            ad utilizzare Servizio BAUMANN esclusivamente per scopi leciti e ammessi dalle disposizioni
                            di legge di volta in volta applicabili, dalle regole di diligenza, della morale e
                            dell’ordine pubblico ed in ogni caso, senza ledere qualsivoglia diritto di terzi.
                        </p>
                        <p>
                            8.3. Correttezza del dato. Il Cliente prende altresì atto che il Fornitore in nessun caso
                            potrà essere ritenuto responsabile per qualsiasi danno dovesse derivare al Cliente stesso o
                            a terzi in conseguenza dell’uso del servizio così come delle elaborazioni generate
                            dall’Applicazione o mediante i Servizi Professionali, essendo il Cliente tenuto in ogni caso
                            a verificare la correttezza delle elaborazioni ottenute.
                        </p>
                        <p>
                            8.4. Lucro cessante. Senza pregiudizio per la generalità di quanto previsto dal presente
                            articolo, il Cliente accetta e prende atto che in nessun caso il Fornitore potrà essere
                            ritenuto responsabile in caso di guasti e/o malfunzionamenti alla rete né, in ogni caso,
                            potrà essere ritenuto responsabile del risarcimento del danno per lucro cessante.
                        </p>
                        <p>
                            8.5 Massimale. In ogni caso, nei limiti consentiti dalla legge, la responsabilità di BAUMANN
                            non potrà mai eccedere l’ammontare della somma spesa per l’Abbonamento negli ultimi 12 mesi
                            dal Cliente.
                        </p>
                    </ol>
                    <h4>9. Proprietà Intellettuale</h4>
                    <ol>
                        <p>
                            9.1. Proprietà Intellettuale. Il Cliente è tenuto ad utilizzare l’Applicazione nel rispetto
                            dei diritti di proprietà intellettuale e/o industriale di BAUMANN e/o di terzi. Il Cliente
                            accetta e riconosce che la titolarità dell’Applicazione, inclusi i codici sorgente e gli
                            eventuali adattamenti, sviluppi e migliorie apportati dal Fornitore dell’Applicazione, della
                            relativa documentazione, nonché tutti i diritti di utilizzazione economica sugli stessi,
                            rimangano in capo a BAUMANN e/o a terzi. Qualsiasi materiale che formi oggetto di diritti di
                            proprietà intellettuale e/o industriale in favore di terzi e che sia messo a disposizione
                            del Cliente tramite l’Applicazione, sarà utilizzato dal Cliente nel rispetto di tali
                            diritti. Il Cliente assume ogni responsabilità in proposito, e si impegna a manlevare ed a
                            tenere indenne, ora per allora, BAUMANN e/o il Fornitore dell’Applicazione da qualsiasi
                            conseguenza pregiudizievole.
                        </p>
                    </ol>
                    <h4>10. Legge Applicabile</h4>
                    <ol>
                        <p>
                            10.1. Legislazione vigente. Le presenti Condizioni di utilizzo saranno disciplinate dalle
                            leggi Italiane e interpretate in conformità alle stesse.
                        </p>
                        <p>
                            10.2. Foro Competente. Qualora le Parti intendano adire l’Autorità giudiziaria ordinaria, il
                            foro esclusivo con riferimento a qualsivoglia controversia relativa al presente Contratto,
                            alla sua interpretazione o esecuzione, sarà il foro di Roma.
                        </p>
                    </ol>
                    <h4>11. Varie</h4>
                    <ol>
                        <p>
                            11.1. Modifiche alle Condizioni di utilizzo. BAUMANN potrebbe modificare periodicamente le
                            presenti Condizioni di utilizzo. Qualora BAUMANN modifichi le condizioni contrattuali in
                            qualsiasi parte, dette modifiche saranno comunicate al Cliente tramite e-mail o all’accesso
                            all’Applicazione.
                        </p>
                        <p>
                            11.2. Comunicazioni elettroniche. Tutte le comunicazioni inviate da BAUMANN relative
                            all’account del Cliente (ad esempio, autorizzazioni di pagamento, fatture, modifiche di
                            password o del Metodo di pagamento, messaggi di conferma, notifiche) saranno esclusivamente
                            in formato elettronico, ad esempio tramite email all'indirizzo email fornito al momento
                            della registrazione.
                        </p>
                        <p>
                            11.3. Validità. Qualora una o più disposizioni delle presenti Condizioni di utilizzo
                            dovessero essere ritenute invalide, illegali o non applicabili, ciò non influirà sulla
                            validità, legittimità e applicabilità delle restanti disposizioni, che continueranno ad
                            avere piena validità ed efficacia.
                        </p>
                        <p>
                            11.4. Dichiarativi fiscali. Per quanto concerne l’elaborazione dei dichiarativi fiscali ed
                            ogni altro adempimento, con la cessazione del contratto cessa qualsiasi obbligo del
                            fornitore alla lavorazione degli stessi anche se riguardano fatti fiscali maturati sotto la
                            vigenza del contratto.
                        </p>
                    </ol>
                    <h4>12. Trattamento dei dati personali</h4>
                    <ol>
                        <p>
                            12.1. Informativa sulla privacy. BAUMANN tutela la privacy dei propri clienti e garantisce
                            che il trattamento dei dati è conforme a quanto previsto dalla normativa sulla privacy di
                            cui Decreto Legislativo 196/2003 (“D. Lgs. 196/2003”), nonché del Regolamento UE 679/2016 in
                            materia di General Data Protection Regulation, recanti disposizioni in materia di protezione
                            dei dati personali. Il cliente dichiara di aver preso visione dell’informativa privacy
                            aggiornata al 25 maggio 2018, presente sul sito http://www.mybaumann.it
                        </p>
                        <p>
                            12.2. Comunicazioni di Marketing. Il cliente da espresso consenso alla ricezione di e-mail a
                            fini di informazione e scambio di informazioni utili alla gestione della sua posizioni
                            fiscale. E’ inoltre consapevole che tali informazioni verranno altresì utilizzate per
                            finalità di marketing relativi a servizi da forniti da BAUMANN.
                        </p>
                    </ol>
                    <p>Ultimo aggiornamento: 1 gennaio 2022</p>
                </div>
            </div>
        </Layout>
    );
}
